.ant-btn,
.ant-input-outlined:focus,
.ant-input-outlined:focus-within {
  box-shadow: none !important;
}

.ant-card-small {
  .ant-card-head {
    padding: 0 4px;
  }
  .ant-card-body {
    padding: 4px;
  }
}

.ant-collapse .ant-collapse-item .ant-collapse-header {
  align-items: center;
}

.ant-descriptions {
  &.ant-descriptions-small .ant-descriptions-row {
    td {
      padding-bottom: 8px;
    }
    &:last-child {
      td {
        padding-bottom: 0;
      }
    }
    .ant-descriptions-item-label,
    .ant-descriptions-item-content {
      line-height: 1;
    }
  }
  .ant-descriptions-item-label {
    font-size: 12px;
  }
  .ant-descriptions-item-content {
    font-weight: 600;
  }
  &.no-border {
    .ant-descriptions-view,
    .ant-descriptions-row,
    .ant-descriptions-item-label,
    .ant-descriptions-item-content {
      border: none !important;
      background: transparent !important;
    }
    .ant-descriptions-item-label {
      align-items: center;
    }
  }
}

.ant-dropdown .ant-dropdown-menu {
  .ant-dropdown-menu-item-selected,
  .ant-dropdown-menu-item-selected:hover {
    color: #041b53;
  }
  .ant-dropdown-menu-item-selected {
    background-color: #041b531a;
  }
  .ant-dropdown-menu-item-selected:hover {
    background-color: #041b533a;
  }
}

.ant-btn-variant-link {
  color: #041b53;
}

svg.ant-menu-item-icon {
  max-width: 24px !important;
  max-height: 24px !important;
}
.ant-menu-inline-collapsed .ant-menu-item-icon {
  max-width: 16px !important;
  max-height: 16px !important;
}

.ant-slider-disabled {
  cursor: default;

  .ant-slider-dot-active {
    display: none;
  }
  .ant-slider-dot {
    width: 1px;
    border: 1px solid #838383 !important;
    border-radius: 0;
  }
  .ant-slider-handle {
    display: none;
  }
}
.ant-slider-track {
  border-radius: 100px !important;
}
.track-green.ant-slider-disabled .ant-slider-track {
  background-color: #00b087 !important;
}
.track-red.ant-slider-disabled .ant-slider-track {
  background-color: red !important;
}
.track-yellow.ant-slider-disabled .ant-slider-track {
  background-color: orange !important;
}

.ant-switch.ant-switch-checked {
  background: #00b087 !important;
}

.ant-table {
  &.ant-table-small .ant-table-cell {
    padding: 6px !important;
  }
  .ant-table-tbody tr {
    td {
      border-bottom: none;
      padding: 14px 16px;
    }
    &:last-child {
      td {
        border-bottom: 1px solid #f0f0f0;
      }
    }
  }
  tr th {
    font-weight: 500 !important;
  }
}

.ant-tooltip-inner {
  color: inherit !important;
}

.ant-pagination {
  color: #a49f9f;

  .ant-pagination-item-active {
    background: #f7f6f9;
    border-color: #f7f6f9;
  }
}

.custom-tabs {
  .ant-tabs-nav::before {
    border: none;
  }
  .ant-tabs-nav-list {
    gap: 16px;
    .ant-tabs-tab {
      padding: 12px 13px;
      gap: 16px;
      border-radius: 8px;
      background: #f7f6f9;
      color: #838383;
      margin: 0 !important;
    }
    .ant-tabs-tab-active {
      background: #041b53;
      border: none !important;

      .ant-tabs-tab-btn {
        color: #fff;
      }
    }
    .ant-tabs-ink-bar {
      display: none;
    }
  }
}

.custom-upload-wrapper {
  min-width: 300px;
  min-height: 100px;
  max-width: 500px;
}

.highlight-block,
.highlight-block .ant-collapse-content {
  background: rgba(255, 0, 0, 0.1);
}

.tenant-switcher {
  .ant-select-selector {
    background: #041b53 !important;
  }
  .ant-select-selection-item,
  .ant-select-arrow {
    color: #fff !important;
  }
}
