@import 'devextreme/dist/css/dx.material.blue.light.css';
@import 'bootstrap/dist/css/bootstrap.css';

@import './antd.override.scss';
@import './dx.override.scss';

html,
body {
  color: #2c2c2c !important;
}

.fw-medium {
  font-weight: 500;
}

.fs-12 {
  font-size: 12px;
}

.fs-14 {
  font-size: 14px;
}

.bg-lightGreen {
  background-color: #d2ffd2 !important;
}

.bg-lightRed {
  background-color: #ffd2d2 !important;
}

.separateRowsTable .ant-table-body {
  table {
    border-collapse: separate !important;
    border-spacing: 0 3px !important;

    tbody {
      tr {
        background-color: #fff;
        &.ant-table-measure-row {
          opacity: 0;
        }
        td {
          border-top: 1px solid #ddd !important;
          border-bottom: 1px solid #ddd !important;
          &:first-child {
            border-left: 1px solid #ddd !important;
          }
          &:last-child {
            border-right: 1px solid #ddd !important;
          }
        }
      }
      .row-new td {
        border-top: 2px solid green !important;
        border-bottom: 2px solid green !important;
        &:first-child {
          border-left: 2px solid green !important;
        }
        &:last-child {
          border-right: 2px solid green !important;
        }
      }

      .row-changed td {
        border-top: 2px solid orange !important;
        border-bottom: 2px solid orange !important;
        &:first-child {
          border-left: 2px solid orange !important;
        }
        &:last-child {
          border-right: 2px solid orange !important;
        }
      }

      .row-deleted td {
        border-top: 2px solid red !important;
        border-bottom: 2px solid red !important;
        &:first-child {
          border-left: 2px solid red !important;
        }
        &:last-child {
          border-right: 2px solid red !important;
        }

        &:not(.row-actions) {
          text-decoration: line-through;
        }
      }

      .ant-table-cell-fix-left,
      .ant-table-cell-fix-right,
      .ant-table-cell-row-hover {
        background-color: inherit !important;
      }
    }
  }

  .ant-table-expanded-row table {
    border-collapse: collapse !important;
  }
}

.timeline-tooltip {
  .ant-timeline-item-last .ant-timeline-item-content {
    min-height: auto;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.hoverable-icon {
  cursor: pointer;
  &:hover path {
    fill: #03a9f4;
  }
}

.full-screen-loader {
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 1100;
  inset: 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  transition: all 0.2s;
}

.mot-descriptions {
  .ant-descriptions-item-label {
    font-weight: 300;
    font-size: 1rem;
    color: #2c2c2c;
  }
  h2 {
    font-size: 2.25rem;
    line-height: 2.9375rem;
    font-weight: 900;
  }

  h3 {
    font-size: 1.125rem;
    line-height: 1.375rem;
    font-weight: 900;
  }
}

.mot-section {
  padding: 4% 0;
  background: #9fd34a;
  h1 {
    font-size: 2.6875rem;
    line-height: 2.9375rem;
    font-weight: 700;
    margin-bottom: 20px;
  }
  p {
    font-size: 18px;
  }
  h3 {
    font-weight: 700;
    font-size: 230%;
  }
}
