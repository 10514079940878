.dx-datagrid-pager {
  border: none;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .dx-page-sizes,
  .dx-page-indexes {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .dx-page-size,
  .dx-page,
  .dx-separator {
    width: 40px;
    height: 40px;
    line-height: 40px !important;
    text-align: center;
    border-radius: 8px;
    padding: 0 !important;
  }

  .dx-pages {
    display: flex;
    align-items: center;

    .dx-page-indexes {
      display: flex;
      align-items: center;
      gap: 10px;
    }
  }

  .dx-selection {
    background-color: #f7f6f9 !important;
    color: #a49f9f !important;
  }
}
